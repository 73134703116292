export const footiesLARFR = {
  MODEM:
    "Le modem doit être activé sur un téléphone intelligent au moyen de l’application Lincoln Way<sup>MC</sup> dans les 60 jours suivant la date d’achat ou de location du véhicule et demeurer actif durant au moins six mois après son activation. Les propriétaires d’un véhicule qui n’est pas doté d’un modem reçoivent aussi des Points bonis de remerciement après l’inscription. Visitez un détaillant participant pour plus de détails. L’application Lincoln Way, compatible avec certaines plateformes de téléphones intelligents, est disponible par téléchargement. Des frais de messagerie texte et de transmission de données peuvent s’appliquer. Offre en vigueur jusqu’au 31 décembre 2025.",
  PURCHASE:
    "Avec tout achat ou toute location conclue au plus tard le 31 décembre 2025, les clients peuvent obtenir 5 000 Points bonis (« boni ») lors d’une première visite d’entretien au détail effectuée dans les 12 mois suivant la date d’achat ou de location du véhicule chez tous les détaillants Lincoln participants. Adhésion au programme Récompenses Accès Lincoln<sup>MC</sup> requise. Limite d’un boni par NIV. Offre valable à la première visite d’entretien au détail seulement. Les membres accumulent des Points chez un détaillant Lincoln participant à l’achat au détail de pièces Ford, Motorcraft<sup>MD</sup> ou Omnicraft<sup>MC</sup> et de toute main-d’œuvre connexe. Aucun Point n’est accumulé à l’achat de pièces de marque autre que Ford ni pour l’entretien de ces pièces. Les services exécutés dans le cadre d’un Programme Protection-entretien Lincoln sont admissibles au boni. Offre valable pour l’achat au détail seulement. Offre non valable pour les visites d’entretien antérieures. Les Points Récompenses Accès Lincoln ne sont pas monnayables et n’ont aucune valeur pécuniaire. Aucun Point n’est accordé pour les taxes et les frais déboursés. Veuillez prévoir 30 jours avant que les Points Récompenses Accès Lincoln soient déposés dans le compte d’un membre. Consultez votre détaillant participant ou les conditions du programme Récompenses Accès Lincoln pour en savoir plus sur l’expiration et l’échange des Points, le renoncement aux Points ou toute autre restriction visant les Points Récompenses Accès Lincoln. Offre valable pour les véhicules achetés ou loués au plus tard le 31 décembre 2025.",
  EARN:
    "Les membres accumulent 5 Points Récompenses Accès Lincoln par dollar dépensé chez tout détaillant Lincoln participant à l’achat au détail de pièces Ford, Motorcraft<sup>MD</sup> ou Omnicraft<sup>MC</sup> ainsi que pour la main-d’œuvre qui s’y rattache. Aucun Point n’est octroyé à l’achat de pièces de marque autre que Ford ni pour l’entretien de telles pièces. Aucun Point n’est accordé pour les taxes et les frais déboursés. Consultez votre détaillant participant ou les conditions du programme Récompenses Accès Lincoln pour en savoir plus sur l’expiration et l’échange des Points, le renoncement aux Points ou toute autre restriction visant l’utilisation des Points Récompenses Accès Lincoln.",
  REDEEM:
    "Les Points ne sont pas monnayables et n’ont aucune valeur pécunaire. Les valeurs d’échange sont approximatives et varient selon les produits et services échangés. Consultez votre détaillant participant ou les conditions du programme Récompenses Accès Lincoln pour en savoir plus sur l’expiration et l’échange des Points, le renoncement aux Points ou toute autre restriction visant l’utilisation des Points.",
};
