export const footiesFPREN = {
  MODEM:
    "Modem must be activated within 60 days from the purchase or lease date through the FordPass App on a smartphone and remain active for at least six months after activation. Buyers of non-modem-equipped vehicles will also receive Thank-You bonus Points after enrollment. Visit a participating dealer for details. The FordPass App, compatible with select smartphone platforms, is available via a download. Message and data rates may apply. Offer valid through December 31, 2025.",
  PURCHASE:
    "Customers with a purchase or lease date on or before December 31, 2025, can earn 5,000 bonus Points (“Bonus”) on the first retail service appointment completed within 12 months of the vehicle purchase or lease date at any participating Ford Dealer. FordPass® Rewards membership required. Limit one (1) Bonus per VIN. Offer valid for first retail service appointment only. Members will earn Points on the retail purchase of a Ford, Motorcraft or OmnicraftTM part and any associated labor from a participating Ford Dealer. Non-Ford parts or services on non-Ford parts will not earn Points. Bonus eligible for service performed under a Ford Maintenance Protection Plan. Offer valid for retail purchase only. Not valid on prior service visit. FordPass Rewards Points are not redeemable for cash and have no monetary value. Points will not be awarded for paid taxes and fees. Please allow 30 days for FordPass Rewards Points to be deposited into a members account. See participating dealer or Ford Pass Rewards Program <a href='https://www.fordpass.com/content/ford_com/fp_app/en_ca/termsprivacy.html'>terms and conditions</a> for information regarding expiration, redemption, forfeiture and other limitations on FordPass Rewards Points. Offer valid on vehicle with a purchase or lease date on or before December 31, 2025.",
  EARN:
    "Members will earn 5 Ford Pass Rewards Points per $1 spent on the retail purchase of a Ford, Motorcraft<sup>®</sup> or Omnicraft<sup>TM</sup> part and any associated labor from a participating Ford dealer. Non-Ford parts or services on non-Ford parts will not earn Points. Points will not be awarded for paid taxes and fees. See participating dealer or Ford Pass Rewards Program terms and conditions for information regarding expiration, redemption, forfeiture and other limitations on FordPass Rewards Points.",
  REDEEM:
    "Points are not redeemable for cash and have no monetary value. Redemption values are approximate and vary by products and services redeemed. See participating dealer or the FordPass Rewards Program terms and conditions for information regarding expiration, redemption, forfeiture and other limitations on Points.",
};
