import React, { useContext, useEffect } from 'react';
import styles from './breadcrumbs.module.scss';
import { Link } from 'react-router-dom';
import { useNavigateTo } from '../../../hooks/use-navigateTo';
import { FdsChevron } from '../chevron/fds-chevron';
import { PointsHeroContext } from '../../../context/points-hero-context';
import ServiceHandler from '../../../services/service-handler';
export var Breadcrumbs = function (_a) {
    var _b;
    var breadcrumbs = _a.breadcrumbs, getValueByTitle = _a.getValueByTitle;
    var totalPoints = useContext(PointsHeroContext)[0].totalPoints;
    var sanatizedLocationSearch = useNavigateTo().sanatizedLocationSearch;
    var cfg = ServiceHandler.ConfigService;
    useEffect(function () {
        sessionStorage.setItem('redeem', 'true');
    }, []);
    return (React.createElement(React.Fragment, null, getValueByTitle((_b = breadcrumbs[0]) === null || _b === void 0 ? void 0 : _b.name) && (React.createElement("div", { key: 'breadcrumbs', className: styles.breadcrumbs }, breadcrumbs.map(function (item, index) {
        return item.url ? (React.createElement("span", { key: 'bc' + index, "data-testid": 'active-link' },
            React.createElement(Link, { id: item.name, to: {
                    pathname: item.url,
                    search: sanatizedLocationSearch(item.search),
                } },
                React.createElement("div", { "data-testid": 'item-name', className: styles.textColor }, getValueByTitle(item.name))),
            React.createElement(FdsChevron, { direction: 'right' }))) : (React.createElement("span", { key: 'bc' + index },
            React.createElement("a", { id: item.name, "data-testid": 'inactive-link' },
                React.createElement("div", { "data-testid": 'item-name', className: styles.textColor }, getValueByTitle(item.name))),
            React.createElement(FdsChevron, { className: styles.chevron, direction: 'right' })));
    })))));
};
export default Breadcrumbs;
