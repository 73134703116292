import { useContext, useEffect, useState } from 'react';
import { PaymentMethodCard, PaymentMethodInfo } from '@fpc/api/paymentapp/';
import { AuthCardSelect } from './AuthCardSelect';
import { AuthNewPaymentMethod } from './AuthNewPaymentMethod';
import { loadingSpinnerBig } from '@fpc/common/Styles';
import { StripeCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import {
  filterGooglePayApplePayFromWallet,
  filterPaymentMethodTypes,
  isAchEnabled,
  sortPaymentMethodsByDefault
} from '@fpc/utils/paymentMethods';
import { LoadingSpinner, translationKeys } from '@fpc/common';
import { CARD_PAYMENT_METHOD, SEPA_PAY_METHOD } from '../index';
import { AuthPaymentRequestButton } from './AuthPaymentRequestButton';
import { useInitializeStripe } from '@fpc/api/stripe/UseInitializeStripe';
import { Banner } from '@fpc/common/components/Banner';
import i18next from '@fpc/common/i18n';

interface StripeAuthCheckoutProps {
  cards: PaymentMethodInfo[];
  fordCustomerId: string;
}

export function StripeAuthCheckout(props: StripeAuthCheckoutProps) {
  const { transaction, errorDispatch, noCreditCards } = useContext(
    StripeCheckoutContext
  );

  const [isPaymentRequestButtonReady, setPaymentRequestButtonReady] =
    useState(false);
  const [checkoutElementReady, setCheckoutElementReady] = useState(false);
  const hasSavedCards: boolean = props.cards.length > 0;
  const showExpressCheckout = !noCreditCards || !isAchEnabled(transaction);
  const { stripe, elements } = useInitializeStripe(errorDispatch);
  const [filteredCards, setFilteredCards] = useState<PaymentMethodInfo[]>([]);

  useEffect(() => {
    if (noCreditCards) {
      transaction.paymentMethodTypes = filterPaymentMethodTypes(
        transaction.paymentMethodTypes,
        CARD_PAYMENT_METHOD
      );
    }

    if (
      transaction.paymentMethodTypes.includes(SEPA_PAY_METHOD.toUpperCase())
    ) {
      transaction.paymentMethodTypes = filterPaymentMethodTypes(
        transaction.paymentMethodTypes,
        SEPA_PAY_METHOD
      );
    }
    if (!showExpressCheckout) {
      setPaymentRequestButtonReady(true);
    }
  }, []);

  useEffect(() => {
    const cards = filterGooglePayApplePayFromWallet(props.cards);
    setFilteredCards(cards);
  }, [props.cards]);

  let authCheckoutElement = <></>;
  if (stripe) {
    if (hasSavedCards) {
      authCheckoutElement = (
        <AuthCardSelect
          cards={sortPaymentMethodsByDefault(filteredCards, false)}
          stripe={stripe}
          isReady={setCheckoutElementReady}
          fordCustomerId={props.fordCustomerId}
        />
      );
    } else {
      authCheckoutElement = (
        <AuthNewPaymentMethod
          stripe={stripe}
          isReady={setCheckoutElementReady}
          fordCustomerId={props.fordCustomerId}
          isFirstCard={true}
        />
      );
    }
  }

  return (
    <div data-testid="auth-checkout-container">
      {(transaction.alternativePaymentMethodTypes.length === 0 ||
        isPaymentRequestButtonReady) &&
      checkoutElementReady ? (
        ''
      ) : (
        <LoadingSpinner style={loadingSpinnerBig} />
      )}
      <div
        hidden={
          !(
            isPaymentRequestButtonReady ||
            transaction.alternativePaymentMethodTypes.length === 0
          ) || !checkoutElementReady
        }
      >
        {stripe !== null && elements !== null && showExpressCheckout && (
          <AuthPaymentRequestButton
            elements={elements}
            stripe={stripe}
            isReadyHandler={setPaymentRequestButtonReady}
            fordCustomerId={props.fordCustomerId}
          />
        )}
        {noCreditCards && isAchEnabled(transaction) && (
          <Banner
            title={i18next.t<string>(translationKeys.checkout.achOnlyInfoTitle)}
            message={i18next.t<string>(translationKeys.checkout.achOnlyInfoMsg)}
          />
        )}
        {authCheckoutElement}
      </div>
    </div>
  );
}
