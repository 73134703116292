import i18next from 'i18next';

const currencies = [
  'USD',
  'CAD',
  'GBP',
  'THB',
  'ZAR',
  'VND',
  'EUR',
  'AUD',
  'NZD',
  'PHP'
] as const;
export type Currency = typeof currencies[number];

type CurrencyAmountProps = {
  amount: number;
  currencyCode: Currency;
};

/**
 * Format currency by locale and language.
 * @param amount The value in the currency's smallest unit. A whole number with no decimals.
 * @param currency Three letter currency code, upper case.
 * @param locale Locale code such as en-US or fr-CA.
 */
export function formatCurrency(
  amount: number,
  currency: Currency,
  locale: string
): string {
  const result = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(amount / 100);
  if (
    locale === 'en-AU' &&
    (currency === 'AUD'.toLowerCase() || currency === 'AUD')
  ) {
    return 'A' + result;
  }
  if (
    locale === 'en-NZ' &&
    (currency === 'NZD'.toLowerCase() || currency === 'NZD')
  ) {
    return 'NZ' + result;
  }
  if (locale === 'vi-VN') {
    return result.replace('₫', 'VNĐ');
  }
  return result;
}

export function formatCurrencyWithNegative(props: CurrencyAmountProps): string {
  const { amount, currencyCode } = props;
  if (amount < 0) {
    return `(${formatCurrency(amount * -1, currencyCode, i18next.language)})`;
  } else {
    return formatCurrency(amount, currencyCode, i18next.language);
  }
}
