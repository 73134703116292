import styled from 'styled-components/macro';
import { Style } from '@fpc/common/Styles';

export const InputLabel = styled.label`
  && {
    font-family: ${Style.fontFamily};
    color: ${Style.color.gray3};
    background-color: ${Style.color.white};
    font-size: 0.75em;
    font-weight: 100;
    letter-spacing: 0.06em;
    text-align: start;
  }

  && a {
    color: ${Style.color.primary};
    text-decoration: underline;
  }

  && a:hover {
    color: ${Style.color.secondary};
    text-decoration: underline;
  }

  && a:focus {
    color: ${Style.color.secondary};
    text-decoration: underline;
  }
`;
export const CheckoutInput = styled.div`
  && {
    border: 1.5px solid #a5a5a5;
    height: 3rem;
    font-size: 1em;
  }

  > input {
    padding-left: .5em;
  }
}

&&:hover {
  border: 1.5px solid #0275eb;
}

&&:focus {
  border: 1.5px solid #0275eb;
}
`;

export const InputGridArea = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    position: relative;
  }
`;
