import React from 'react';
import {
  CheckoutInput,
  InputGridArea,
  InputLabel
} from '@fpc/common/components/InputComponents';
import { TextInput } from '@fpc/checkout/payu/Style';

interface CardExpiryMonthInputProps {
  expiryMonth: string;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CardExpiryMonthInput: React.FC<CardExpiryMonthInputProps> = ({
  expiryMonth,
  handleKeyPress,
  handleChange,
  handleBlur
}) => {
  return (
    <InputGridArea style={{ gridArea: '2 / 1 / auto / 1' }}>
      <InputLabel htmlFor="expiry-month">
        {/*{i18n.t<string>(translationKeys.checkout.expirationDate)}*/}
        Expiry Month
      </InputLabel>
      <CheckoutInput
        className="form-control"
        id="expiry-month"
        style={{
          borderBottomLeftRadius: '.25rem',
          borderTopLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
          borderTopRightRadius: '.25rem',
          padding: '0.9em 0.5em',
          height: '1.5rem'
        }}
      >
        <TextInput
          data-encrypt="month"
          id={'month'}
          placeholder={'MM'}
          data-testid={'expiry-month'}
          minLength={0}
          maxLength={2}
          type={'text'}
          onKeyDown={handleKeyPress}
          style={{
            paddingLeft: '0rem'
          }}
          value={expiryMonth}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </CheckoutInput>

      <span
        className="helper-text"
        id="exp-month-help"
        data-testid="exp-month-help"
      />
    </InputGridArea>
  );
};

export default CardExpiryMonthInput;
