import { useEffect, useState } from 'react';
import {
  decodePaymentInfoToken,
  verifyDigitalSignature
} from '@fpc/api/paymentapp';
import { isValidJsonPaymentInfoForDigitalSign } from '@fpc/utils/paymentInfoJsonValidator';
import {
  PaymentObjectType,
  PayUTransactionDetails,
  StripeTransactionDetails,
  TransactionDetails,
  TwocTwopTransactionDetails
} from '@fpc/common/transactionInterfaces';
import { MultiMerchantStripeTransactionDetails } from 'checkout/features/multi-merchant/types/PaymentInfoToken';

function determineTokensBooleanStatus(
  inputBffBaseUrl: string | null,
  bearerToken: string | null,
  paymentObjectType: PaymentObjectType,
  paymentInfoToken: string | null,
  inputCustomerAuthToken: string | null
) {
  const hasBffAndTokens = !!(inputBffBaseUrl && bearerToken);
  const hasBffOrTokens = inputBffBaseUrl || bearerToken;
  const hasValidBffOrAuthTokens = hasBffOrTokens && !hasBffAndTokens;
  const hasJWTAndNotBFF = paymentObjectType === 'JWT' && !inputBffBaseUrl;
  const isCatAndDigitalSignature =
    inputCustomerAuthToken && paymentObjectType === 'DIGITALSIGN';

  const hasValidDigitalSignParams: boolean =
    paymentObjectType === 'DIGITALSIGN' &&
    isValidJsonPaymentInfoForDigitalSign(paymentInfoToken) &&
    !isCatAndDigitalSignature &&
    !bearerToken;

  const isValidSecurityConfig: boolean =
    hasValidDigitalSignParams || !!(bearerToken && hasJWTAndNotBFF);

  const digitalSignature: string | undefined = hasValidDigitalSignParams
    ? JSON.parse(paymentInfoToken!).signature
    : undefined;

  return {
    hasBffAndTokens,
    hasValidBffOrAuthTokens,
    hasValidDigitalSignParams,
    isValidSecurityConfig,
    digitalSignature
  };
}

export function useGetTransactionDetails(
  inputBffBaseUrl: string | null,
  bearerToken: string | null,
  inputCustomerAuthToken: string | null,
  paymentObjectType: PaymentObjectType,
  paymentInfoToken: string | null,
  isPreAuth?: boolean
) {
  const [decodedPaymentToken, setDecodedPaymentToken] = useState<
    | TransactionDetails
    | StripeTransactionDetails
    | MultiMerchantStripeTransactionDetails
    | PayUTransactionDetails
    | TwocTwopTransactionDetails
    | null
  >(null);
  const [isPaymentDecodeError, setPaymentDecodeError] =
    useState<boolean>(false);
  const [bffBaseUrl, setBffBaseUrl] = useState<string | null>(inputBffBaseUrl);
  const [customerAuthToken, setCustomerAuthToken] = useState<string | null>(
    inputCustomerAuthToken
  );
  const [isTransactionProcessing, setTransactionProcessing] = useState(true);

  const {
    hasBffAndTokens,
    hasValidBffOrAuthTokens,
    hasValidDigitalSignParams,
    isValidSecurityConfig,
    digitalSignature
  } = determineTokensBooleanStatus(
    inputBffBaseUrl,
    bearerToken,
    paymentObjectType,
    paymentInfoToken,
    inputCustomerAuthToken
  );

  useEffect(() => {
    if (isValidSecurityConfig && hasValidDigitalSignParams) {
      verifyDigitalSignature(paymentInfoToken!, bffBaseUrl)
        .then((response) => {
          setDecodedPaymentToken(response);
        })
        .catch((err) => {
          if (bffBaseUrl && !err.unrecoverable) {
            setBffBaseUrl(null);
            setCustomerAuthToken(null);
          } else {
            console.error('Error loading payment processor:', err);
            setPaymentDecodeError(true);
          }
        });
    } else if (isValidSecurityConfig && hasValidBffOrAuthTokens) {
      decodePaymentInfoToken(
        paymentInfoToken!,
        paymentObjectType,
        bearerToken!,
        inputBffBaseUrl,
        isPreAuth
      )
        .then((response) => {
          setDecodedPaymentToken(response);
        })
        .catch((err) => {
          console.error('Error loading payment processor:', err);
          setPaymentDecodeError(true);
        });
    }
  }, [bffBaseUrl]);

  useEffect(() => {
    if (
      isTransactionProcessing &&
      (decodedPaymentToken || isPaymentDecodeError)
    ) {
      setTransactionProcessing(false);
    }
  }, [decodedPaymentToken, isPaymentDecodeError, isTransactionProcessing]);

  return {
    isValidSecurityConfig: isValidSecurityConfig,
    decodedPaymentToken: decodedPaymentToken,
    customerAuthToken: customerAuthToken,
    bffBaseUrl: bffBaseUrl,
    digitalSignature: digitalSignature,
    isPaymentDecodeError: isPaymentDecodeError,
    isTransactionProcessing: isTransactionProcessing,
    hasBffAndTokens: hasBffAndTokens
  };
}
