import React from 'react';
import {
  CheckoutInput,
  InputGridArea,
  InputLabel
} from '@fpc/common/components/InputComponents';
import { IconsContainer, TextInput } from '@fpc/checkout/payu/Style';
import CardBrandIcons from '@fpc/checkout/payu/CardBrandIcons';
import i18n from 'i18next';
import { translationKeys } from '@fpc/common';

interface CardNumberInputProps {
  cardNumber: string;
  icons: string[];
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CardNumberInput: React.FC<CardNumberInputProps> = ({
  cardNumber,
  icons,
  handleKeyPress,
  handleChange,
  handleBlur
}) => {
  return (
    <InputGridArea style={{ gridArea: '1 / 1 / auto / -1' }}>
      <InputLabel htmlFor="card-number">
        {i18n.t<string>(translationKeys.checkout.cardNumber)}
      </InputLabel>
      <div
        className="input-group"
        style={{ display: '', position: 'relative' }}
      >
        <CheckoutInput
          className="form-control"
          style={{
            borderRadius: '.25rem',
            padding: '0.9em 0.5em',
            display: 'flex',
            height: '1.5rem'
          }}
        >
          <div
            id="card-number"
            data-testid="card-number-container"
            style={{ width: '75%' }}
          >
            <TextInput
              id="cardnumber"
              data-encrypt="cardnumber"
              placeholder={i18n.t<string>(translationKeys.checkout.cardNumber)}
              data-testid={'card-number'}
              type={'text'}
              maxLength={16}
              onKeyDown={handleKeyPress}
              value={cardNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          {
            <IconsContainer data-testid="card-icons">
              {icons && <CardBrandIcons icons={icons} />}
            </IconsContainer>
          }
        </CheckoutInput>
      </div>
      <span className="helper-text" id="ccn-help" data-testid="ccn-help" />
    </InputGridArea>
  );
};

export default CardNumberInput;
