import { isLocal } from '../../flags';

export function enterCustomAction(name: string): number {
  try {
    const dtrum = window?.dtrum;
    if (
      !(
        dtrum &&
        'enterAction' in dtrum &&
        typeof dtrum.enterAction === 'function'
      )
    ) {
      return 0;
    }
    return (
      dtrum.enterAction(name, undefined, undefined, window.location.href) ?? 0
    );
  } catch {
    return 0;
  }
}

export function leaveCustomAction(
  actionId: number,
  actionProperties: Record<string, string>
) {
  try {
    const dtrum = window?.dtrum;
    if (
      dtrum &&
      'addActionProperties' in dtrum &&
      typeof dtrum.addActionProperties === 'function' &&
      'leaveAction' in dtrum &&
      typeof dtrum.leaveAction === 'function'
    ) {
      dtrum.addActionProperties(
        actionId,
        undefined,
        undefined,
        actionProperties
      );
      dtrum.leaveAction(actionId);
    }
  } catch {}
}

export function reportCustomError(
  actionId: number,
  name: string,
  description: string
) {
  try {
    const dtrum = window?.dtrum;
    if (
      dtrum &&
      'reportCustomError' in dtrum &&
      typeof dtrum.reportCustomError === 'function'
    ) {
      dtrum.reportCustomError(name, description, '', actionId);
    }
  } catch {}
}

export function log(message: string) {
  if (isLocal) {
    console.log(message);
  }
}
