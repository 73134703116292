import styled from 'styled-components/macro';
import { Style } from '../Styles';

export const UCLButton = styled.button`
  && {
    font-family: 'FordF1-Bold';
    font-size: 14px;
    line-height: 20px;
    font-stretch: condensed;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: none;
    border: none;
    padding: 10px 24px;
    position: relative;
    color: ${Style.color.text};
    z-index: 1;
    outline: none;
    outline-offset: 0.8em;
    cursor: pointer;
    margin: 0;
    font-weight: 700;
    width: 188px;
    height: 40px;
  }

  &&:hover,
  &&:focus {
    font-family: 'FordF1-Bold';
    font-size: 14px;
    line-height: 20px;
    font-stretch: condensed;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    background: none;
    border: none;
    padding: 10px 24px;
    position: relative;
    color: ${Style.color.text};
    z-index: 1;
    outline: none;
    outline-offset: 0.8em;
    cursor: pointer;
    margin: 0;
  }

  &&:hover::after,
  &&:focus::after {
    background-color: #044ea7;
  }

  &&::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2em;
    border: 0.1em solid transparent;
    background-color: #066fef;
    z-index: -1;
    will-change: transform, background-color, border-color;
  }

  &&:disabled {
    cursor: not-allowed;
    font-family: 'FordF1-Bold';
    font-size: 14px;
    line-height: 20px;
    font-stretch: condensed;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    background: none;
    border: none;
    padding: 10px 24px;
    position: relative;
    color: #808080;
    z-index: 1;
    outline: none;
    outline-offset: 0.8em;
    margin: 0;
  }

  &&:disabled::after {
    background-color: #e5e5e5;
    box-shadow: none;
  }
  &&:disabled svg {
    fill: #808080;
  }
`;
