import { FormEvent, useContext, useEffect, useState } from 'react';
import { callHandleNextAction } from '@fpc/api/stripe/HandleNextAction';
import {
  Stripe,
  StripeElements,
  StripeElementsOptionsMode,
  StripeError
} from '@stripe/stripe-js';
import {
  Button,
  buttonPayNow,
  Checkbox,
  getIcon,
  PAYMENT_INITIATOR,
  paymentButtonProcessing,
  REQUIRES_ACTION_STATUS,
  StripeElementErrorDisplay,
  translationKeys
} from '@fpc/common';
import { cardElementStyle, STRIPE_APPEARANCE, Style } from '@fpc/common/Styles';
import useStripePaymentElements from '@fpc/api/stripe/UseStripePaymentElements';
import {
  CHECKOUT_ELEMENT,
  StripeCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import {
  AuthPaymentRequest,
  makeAuthenticatedPayment,
  MultiMerchantPaymentResponse
} from '@fpc/api/paymentapp/MakeAuthenticatedPayment';
import i18next from '@fpc/common/i18n';
import { isLowerEnvironment, isNotProd } from '../../flags';
import { CheckboxShadowbox } from '@fpc/common/components/Checkbox';
import { TermsAndConditions } from '@fpc/common/components/TermsAndConditions';
import {
  buildManualRedirectUrl,
  buildManualRedirectUrlForMultiMerchant
} from '@fpc/utils/buildManualRedirectUrl';
import { handleRejectError } from '@fpc/utils/errorHandling';
import { initializeStripe } from '@fpc/api/stripe/Stripe';
import { createCardSetupIntent } from '@fpc/api/customerapp';
import {
  ACSS_DEBIT_METHOD,
  BACS_DIRECT_DEBIT,
  CARD_PAYMENT_METHOD,
  CONNECT_PAYMENT_METHOD_TYPES,
  IDEAL_PAY_METHOD,
  PAY_BY_BANK_METHOD,
  SEPA_PAY_METHOD
} from '../index';
import { CanCloseButton } from '@fpc/common/components/CanCloseButton';
import { PayByAcssDebit } from './PayByAcssDebit';
import { PayByAcssDebitLink } from './PayByAcssDebitLink';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import {
  filterPaymentMethodTypes,
  hasCardElement,
  isAchEnabled,
  isAcssDebitPresent,
  isVariableDebitEnabled,
  shouldShowAcssDebitFirst
} from '@fpc/utils/paymentMethods';
import { PayByBank } from './PayByBank';
import { FordStyles } from '@fpc/common/FordStyles';
import { Ideal } from './Ideal';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import { isMultiMerchantFlow } from '@fpc/checkout/features/multi-merchant';
import { observedStripe } from '@fpc/common/monitoring/utils';

interface NewPaymentMethodProps {
  stripe: Stripe;
  isReady: (value: boolean) => void;
  setIsOpen?: (value: boolean) => void;
  fordCustomerId: string;
  isFirstCard: boolean;
}

export function AuthNewPaymentMethod(props: NewPaymentMethodProps) {
  const {
    transaction,
    tokens,
    stripeCustomerId,
    redirectUrl,
    errorDispatch,
    isPreAuth,
    bffBaseUrl,
    redirectStatus,
    paymentMethodDisplayOrder,
    noCreditCards,
    blockedCardBrands
  } = useContext(StripeCheckoutContext);
  const [isPayByAcssDebit, setPayByAcssDebit] = useState(false);
  const canClose = props.setIsOpen !== undefined;
  const [message, setMessage] = useState('');
  const [isSubmitProcessing, setSubmitProcessing] = useState(false);
  const [isSavePaymentMethod, setIsSavePaymentMethod] = useState(false);
  const [isSavePaymentMethodAsDefault, setIsSavePaymentMethodAsDefault] =
    useState(false);
  const [isPayByBank, setPayByBank] = useState(false);
  const [isIdeal, setIsIdeal] = useState(false);
  const [paymentMethodType, setPaymentMethodType] = useState('');
  const [paymentClientSecret, setPaymentClientSecret] = useState('');
  const [stripeConnect, setStripeConnect] = useState<Stripe | null>(null);
  const [stripeConnectCustomerId, setStripeConnectCustomerId] = useState('');
  const options = {
    mode: 'setup',
    locale: i18next.language,
    currency: transaction.currency,
    appearance: STRIPE_APPEARANCE,
    disallowedCardBrands: blockedCardBrands?.map((card) => card.toLowerCase()),
    payment_method_types: filterPaymentMethodsTypes().map((p) =>
      p.toLowerCase()
    ),
    payment_method_options: {
      us_bank_account: { verification_method: 'instant_or_skip' }
    }
  } as unknown as StripeElementsOptionsMode;
  const [elements] = useState<StripeElements>(props.stripe.elements(options));
  const isAcssDebit = transaction.paymentMethodTypesExcluded
    ? isAcssDebitPresent(transaction.paymentMethodTypesExcluded)
    : false;
  const isAcssDisplayedFirst =
    isAcssDebit && shouldShowAcssDebitFirst(transaction.paymentMethodTypes);
  const canMountCardElementForAcssDebit = hasCardElement(
    transaction.paymentMethodTypes
  );
  const paymentMethodTypes = options.paymentMethodTypes
    ? options.paymentMethodTypes.join(',')
    : '';
  const { mountPaymentElements, isPaymentMounted, isFormComplete } =
    useStripePaymentElements(
      props.stripe,
      options,
      elements,
      errorDispatch,
      paymentMethodDisplayOrder ? paymentMethodDisplayOrder : paymentMethodTypes
    );
  const bffUrl = bffBaseUrl ? bffBaseUrl : '';
  const [shouldShowCardElement, setShouldShowCardElement] = useState(true);

  const isPaymentMethodType = (type: string): boolean =>
    transaction.paymentMethodTypes.includes(type.toUpperCase());

  useEffect(() => {
    mountPaymentElements('#payment-element', (event) => {
      setPaymentMethodType(event.value.type);
      if (isAcssDebit && !canMountCardElementForAcssDebit) {
        setShouldShowCardElement(false);
        setPayByAcssDebit(true);
      }
    });
  }, []);

  useEffect(() => {
    if (redirectStatus === 'failed') {
      setMessage(
        i18next.t<string>(translationKeys.checkout.redirectStatusFailure)
      );
    }
  }, []);

  useEffect(() => {
    setIsSavePaymentMethodAsDefault(!canClose && isSavePaymentMethod);
  }, [isSavePaymentMethod]);

  useEffect(() => {
    if (paymentMethodType !== 'card') {
      setIsSavePaymentMethod(false);
      setIsSavePaymentMethodAsDefault(false);
    }
  }, [paymentMethodType]);

  useEffect(() => {
    if (isPaymentMounted && !isPayByBank) {
      props.isReady(true);
    }
  }, [isPaymentMounted, props]);

  function getConnectPaymentType() {
    if (isPayByBank) {
      return PAY_BY_BANK_METHOD;
    }
    if (isIdeal) {
      return IDEAL_PAY_METHOD;
    }
    return ACSS_DEBIT_METHOD;
  }

  useEffect(() => {
    if (isPayByBank || isPayByAcssDebit || isIdeal) {
      setStripeConnect(null);
      props.isReady(false);
      const authPaymentRequest: AuthPaymentRequest = {
        checkoutTokens: tokens,
        stripeCustomerId: stripeCustomerId!,
        paymentMethodId: null,
        paymentMethodType: getConnectPaymentType(),
        isSavePaymentMethod: false,
        setPaymentMethodAsDefault: false,
        fordCustomerId: props.fordCustomerId,
        paymentInitiator: PAYMENT_INITIATOR.CUSTOMER,
        isPreAuth: false,
        bffBaseUrl: bffBaseUrl!
      };
      Promise.all([
        initializeStripe(
          (transaction as StripeTransactionDetails).merchantAccountId
        ),
        makeAuthenticatedPayment(authPaymentRequest)
      ]).then((results) => {
        setStripeConnect(results[0]);
        let paymentResponse = results[1];
        setPaymentClientSecret(paymentResponse.paymentIntentClientSecret);
        setStripeConnectCustomerId(paymentResponse.stripeConnectAccountId!);
      });
    } else {
      if (shouldShowCardElement) {
        mountPaymentElements('#payment-element', (event) => {
          setPaymentMethodType(event.value.type);
          if (isAcssDebit && !canMountCardElementForAcssDebit) {
            setShouldShowCardElement(false);
            setPayByAcssDebit(true);
          }
        });
      }
    }
  }, [isIdeal, isPayByBank, isPayByAcssDebit]);

  function filterPaymentMethodsTypes() {
    let paymentMethodTypes = transaction.paymentMethodTypes;
    if (noCreditCards && isAchEnabled(transaction)) {
      paymentMethodTypes = filterPaymentMethodTypes(
        transaction.paymentMethodTypes,
        CARD_PAYMENT_METHOD,
        transaction.paymentMethodTypesExcluded
      );
    }
    if (paymentMethodTypes.includes('PAY_BY_BANK')) {
      paymentMethodTypes = paymentMethodTypes.filter(
        (paymentMethod) =>
          paymentMethod.toLowerCase() !== PAY_BY_BANK_METHOD.toLowerCase()
      );
    }
    if (paymentMethodTypes.includes('IDEAL')) {
      paymentMethodTypes = paymentMethodTypes.filter(
        (paymentMethod) =>
          paymentMethod.toLowerCase() !== IDEAL_PAY_METHOD.toLowerCase()
      );
    }

    if (isPreAuth || transaction.paymentMethodTypes.length === 0) {
      paymentMethodTypes = [CARD_PAYMENT_METHOD];
    } else if (
      isVariableDebitEnabled(transaction as StripeTransactionDetails)
    ) {
      paymentMethodTypes = paymentMethodTypes.filter(
        (it) =>
          it.toLowerCase() !== BACS_DIRECT_DEBIT.toLowerCase() &&
          it.toLowerCase() !== SEPA_PAY_METHOD.toLowerCase()
      );
    }
    if (transaction.paymentMethodTypesExcluded) {
      paymentMethodTypes = paymentMethodTypes.filter(
        (paymentMethod) =>
          transaction.paymentMethodTypesExcluded?.indexOf(paymentMethod) === -1
      );
    }
    return paymentMethodTypes;
  }

  function handleNextActionErrorCallback(error: StripeError) {
    setMessage(
      error.message ?? i18next.t(translationKeys.common.technicalErrorPayment)
    );
    if (isLowerEnvironment) {
      console.warn(error);
    }
    setSubmitProcessing(false);
  }

  async function handleCreateCardSetupIntent() {
    return createCardSetupIntent({
      bearerToken: tokens.bearerToken,
      stripeCustomerId: stripeCustomerId!,
      paymentMethodTypes: isPreAuth
        ? [CARD_PAYMENT_METHOD]
        : transaction.paymentMethodTypes.filter(
            (it) => !CONNECT_PAYMENT_METHOD_TYPES.includes(it.toLowerCase())
          ),
      bffBaseUrl: bffBaseUrl!
    })
      .then((value) => {
        return Promise.resolve(value);
      })
      .catch((err) => {
        if (err.unrecoverable) {
          errorDispatch(ErrorCondition.Unrecoverable);
          dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
        }
        errorDispatch(ErrorCondition.Present);
      });
  }

  async function createAndConfirmSetupIntent(paymentMethodId: string) {
    let setupIntentClientSecret = await handleCreateCardSetupIntent();

    if (setupIntentClientSecret) {
      const { setupIntent, error } = await observedStripe(
        () =>
          props.stripe.confirmSetup({
            clientSecret: setupIntentClientSecret ?? '',
            confirmParams: {
              payment_method: paymentMethodId,
              return_url: window.location.href
            },
            redirect: 'if_required'
          }),
        'Confirm Setup Intent'
      );

      if (error) {
        handleLog(error);
        setMessage(error?.message ?? '');
        setSubmitProcessing(false);
        console.log('processing ', isSubmitProcessing);
      } else {
        return setupIntent;
      }
    }
  }

  async function makeAuthenticatedPaymentCall(paymentMethodId: string) {
    const authPaymentRequest: AuthPaymentRequest = {
      checkoutTokens: tokens,
      stripeCustomerId: stripeCustomerId!,
      paymentMethodId: paymentMethodId,
      paymentMethodType: paymentMethodType,
      isSavePaymentMethod: isSavePaymentMethod,
      setPaymentMethodAsDefault: isSavePaymentMethodAsDefault,
      fordCustomerId: props.fordCustomerId,
      paymentInitiator: PAYMENT_INITIATOR.CUSTOMER,
      isPreAuth,
      bffBaseUrl: bffUrl
    };
    await makeAuthenticatedPayment(authPaymentRequest)
      .then(async (response) => {
        if (response.status === REQUIRES_ACTION_STATUS) {
          await callHandleNextAction(
            response,
            () => {
              window.location.href = buildManualRedirectUrl(
                redirectUrl,
                (transaction as StripeTransactionDetails).merchantAccountId,
                response.paymentIntentClientSecret,
                paymentMethodType
              );
            },
            handleNextActionErrorCallback
          );
        } else {
          if (isMultiMerchantFlow(transaction)) {
            window.location.href = buildManualRedirectUrlForMultiMerchant(
              redirectUrl,
              response as MultiMerchantPaymentResponse
            );
          } else {
            window.location.href = buildManualRedirectUrl(
              redirectUrl,
              (transaction as StripeTransactionDetails).merchantAccountId,
              response.paymentIntentClientSecret,
              paymentMethodType
            );
          }
        }
      })
      .catch(async (reject) => {
        let errorMessage =
          translationKeys.checkout.technicalErrorPaymentRefresh;
        errorMessage = handleRejectError(
          reject,
          errorMessage,
          errorDispatch,
          CHECKOUT_ELEMENT
        );
        setMessage(errorMessage);
        setSubmitProcessing(false);
      });
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitProcessing(true);
    setMessage('');
    const { paymentMethod, error } = await observedStripe(
      () =>
        props.stripe.createPaymentMethod({
          elements: elements
        }),
      'Create Auth Payment Method'
    );

    if (paymentMethod) {
      if (isSavePaymentMethod || paymentMethod.type == 'us_bank_account') {
        const setupIntent = await createAndConfirmSetupIntent(paymentMethod.id);
        if (setupIntent) {
          makeAuthenticatedPaymentCall(paymentMethod.id);
        }
      } else {
        makeAuthenticatedPaymentCall(paymentMethod.id);
      }
    } else {
      handleLog(error);
      setMessage(error?.message ?? '');
      setSubmitProcessing(false);
    }
  };

  const PayByBankIcon = getIcon('pay_by_bank');
  const IdealIcon = getIcon('ideal');
  function handleLog(error: unknown) {
    if (isNotProd()) {
      console.warn(error);
    }
  }

  function handleClose() {
    if (canClose) {
      props.setIsOpen!(false);
    }
  }

  function togglePayByAcssDebit(shouldOpenAcssDebit: boolean): void {
    props.isReady(false);
    setPayByAcssDebit(shouldOpenAcssDebit);
  }

  if (isPayByBank && paymentClientSecret != null && stripeConnect) {
    return (
      <PayByBank
        paymentIntentClientSecret={paymentClientSecret}
        stripeConnect={stripeConnect}
        isReady={props.isReady}
        setIsOpen={setPayByBank}
        fordCustomerId={props.fordCustomerId}
      />
    );
  }
  if (isPayByAcssDebit && paymentClientSecret != null && stripeConnect) {
    props.isReady(true);
    return (
      <PayByAcssDebit
        paymentIntentClientSecret={paymentClientSecret}
        stripe={stripeConnect}
        isPreAuth={isPreAuth}
        isAuthenticatedCheckout={true}
        isMit={false}
        togglePayByAcssDebit={togglePayByAcssDebit}
        mitCloseButtonEnable={() => {}}
        isOnlyPayByAcssDebit={!shouldShowCardElement}
        isAcssDebitFirst={isAcssDisplayedFirst}
        handleClose={handleClose}
        stripeConnectCustomerId={stripeConnectCustomerId}
      />
    );
  }
  if (isIdeal && paymentClientSecret != null && stripeConnect) {
    return (
      <Ideal
        paymentIntentClientSecret={paymentClientSecret}
        stripeConnect={stripeConnect}
        isReady={props.isReady}
        setIsOpen={setIsIdeal}
        fordCustomerId={props.fordCustomerId}
      />
    );
  }

  return (
    <form
      hidden={!isPaymentMounted}
      onSubmit={handleSubmit}
      data-testid="new-payment-method-container"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px 16px'
        }}
      >
        <h2 className={'new-payment-method'}>
          {i18next.t<string>(translationKeys.checkout.useNewPayment)}
        </h2>
        <span style={{ flexGrow: '1' }} />
        {canClose && <CanCloseButton handleClose={handleClose} />}
      </div>
      {isAcssDebit && isAcssDisplayedFirst && (
        <div
          style={{ textAlign: 'left', padding: '15px' }}
          data-testid={'acss-element-in-first'}
        >
          <PayByAcssDebitLink
            togglePayByAcssDebit={togglePayByAcssDebit}
          ></PayByAcssDebitLink>
        </div>
      )}
      <div
        id="payment-element"
        style={cardElementStyle}
        data-testid={'payment-element'}
        hidden={!shouldShowCardElement}
      >
        {/*Payment Element gets injected here.*/}
      </div>
      <div
        style={{
          textAlign: 'left',
          paddingLeft: '6px',
          marginTop: '4px',
          paddingTop: '3px',
          paddingBottom: '40px',
          lineHeight: '5px'
        }}
      >
        {isAcssDebit && !isAcssDisplayedFirst && (
          <div data-testid={'acss-element-in-last'}>
            <PayByAcssDebitLink
              togglePayByAcssDebit={togglePayByAcssDebit}
            ></PayByAcssDebitLink>
          </div>
        )}
        {isPaymentMethodType(PAY_BY_BANK_METHOD) && (
          <span
            aria-label={i18next.t<string>(
              translationKeys.checkout.payByBankLinkText
            )}
          >
            <div>
              <PayByBankIcon
                style={{
                  height: '1em',
                  marginRight: '29px',
                  marginTop: '2px'
                }}
              />
              <a
                href="#"
                role="link"
                style={{
                  cursor: 'pointer',
                  color: FordStyles.color.primary,
                  fontFamily: FordStyles.fontFamilyBold,
                  fontSize: '12.7px',
                  lineHeight: '12px',
                  paddingBottom: '10px'
                }}
                onClick={(event) => {
                  event.preventDefault();
                  setPayByBank(true);
                }}
              >
                {i18next.t<string>(translationKeys.checkout.payByBankLinkText)}
              </a>
            </div>
          </span>
        )}
        {isPaymentMethodType(IDEAL_PAY_METHOD) && (
          <div aria-label={'Ideal Payment Method'}>
            <IdealIcon
              style={{
                height: '1em',
                marginRight: '17px',
                marginTop: '2px',
                width: '2.25em'
              }}
            />
            <a
              href="#"
              role="link"
              style={{
                cursor: 'pointer',
                color: FordStyles.color.primary,
                fontFamily: FordStyles.fontFamilyBold,
                fontSize: '12.7px',
                lineHeight: '12px',
                paddingBottom: '10px',
                position: 'absolute',
                transform: 'translateY(3.5px)'
              }}
              onClick={(event) => {
                event.preventDefault();
                setIsIdeal(true);
              }}
            >
              {'Ideal'}
            </a>
          </div>
        )}
      </div>
      <CheckboxShadowbox>
        <Checkbox
          label={i18next.t<string>(
            translationKeys.common.savePaymentMethodCheck
          )}
          onChange={setIsSavePaymentMethod}
          isChecked={isSavePaymentMethod}
          uniqueId="save-payment-method-checkbox"
          className="fordpay-checkout"
        />
        {isSavePaymentMethod && (
          <Checkbox
            label={i18next.t<string>(
              translationKeys.common.makeDefaultPaymentMethod
            )}
            onChange={setIsSavePaymentMethodAsDefault}
            isChecked={isSavePaymentMethodAsDefault}
            uniqueId="default-payment-method-checkbox"
            disabled={props.isFirstCard}
          />
        )}
        <div style={{ textAlign: 'start', margin: '-.5em 2.725em 0 2.725em' }}>
          <span style={{ color: Style.color.gray2, fontSize: '.875em' }}>
            {i18next.t<string>(translationKeys.checkout.checkoutFaster)}
          </span>
        </div>
      </CheckboxShadowbox>
      <TermsAndConditions translationGroup={translationKeys.checkout} />
      <StripeElementErrorDisplay message={message} />
      <Button
        disabled={!isFormComplete || isSubmitProcessing}
        id="submit"
        style={{ marginTop: '0.5em' }}
      >
        {isSubmitProcessing
          ? paymentButtonProcessing()
          : buttonPayNow(transaction.amount, transaction.currency)}
      </Button>
    </form>
  );
}
