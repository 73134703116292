import React from 'react';
import {
  CheckoutInput,
  InputGridArea,
  InputLabel
} from '@fpc/common/components/InputComponents';
import { TextInput } from '@fpc/checkout/payu/Style';
import i18n from '@fpc/common/i18n';
import { translationKeys } from '@fpc/common';

interface CardCvvInputProps {
  cardCvv: string;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CardCvvInput: React.FC<CardCvvInputProps> = ({
  cardCvv,
  handleKeyPress,
  handleChange,
  handleBlur
}) => {
  return (
    <InputGridArea style={{ gridArea: '2 / 3 / auto / 3', height: '2rem' }}>
      <InputLabel htmlFor="card-cvv">
        {i18n.t<string>(translationKeys.checkout.securityCode)}
      </InputLabel>
      <CheckoutInput
        className="form-control bluesnap-checkout-input-border"
        id="card-cvv"
        style={{
          borderBottomLeftRadius: '.25rem',
          borderTopLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
          borderTopRightRadius: '.25rem',
          padding: '0.9em 0.5em',
          height: '1.5rem'
        }}
      >
        <TextInput
          id="cvv"
          data-encrypt="cvv"
          placeholder={'CVV2/CVC2'}
          data-testid={'card-cvv'}
          minLength={0}
          maxLength={4}
          type={'password'}
          onKeyDown={handleKeyPress}
          style={{
            paddingLeft: '0rem'
          }}
          value={cardCvv}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </CheckoutInput>
      <span className="helper-text" id="cvv-help" data-testid="cvv-help" />
    </InputGridArea>
  );
};

export default CardCvvInput;
