import {
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  NEW_PAYMENT_METHOD_INFO,
  PaymentMethodInfo
} from '@fpc/api/paymentapp';
import {
  buttonPayNow,
  DropDown,
  DropDownItem,
  PAYMENT_INITIATOR,
  REQUIRES_ACTION_STATUS,
  translationKeys
} from '@fpc/common';
import {
  CHECKOUT_ELEMENT,
  StripeCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import {
  AuthPaymentRequest,
  confirmPaymentIntentConnectAccount,
  makeAuthenticatedPayment,
  MultiMerchantPaymentResponse,
  PaymentResponse
} from '@fpc/api/paymentapp/MakeAuthenticatedPayment';
import { Stripe } from '@stripe/stripe-js';
import { AuthNewPaymentMethod } from './AuthNewPaymentMethod';
import i18next from '@fpc/common/i18n';
import { paymentMethodSelect } from '@fpc/common/format/paymentMethodSelect';
import { errorTextStyle } from '@fpc/common/Styles';
import { TermsAndConditions } from '@fpc/common/components/TermsAndConditions';
import { PayButton } from '@fpc/common/components/PayButtonProps';
import {
  buildManualRedirectUrl,
  buildManualRedirectUrlForMultiMerchant
} from '@fpc/utils/buildManualRedirectUrl';
import { callHandleNextAction } from '@fpc/api/stripe/HandleNextAction';
import { ACSS_DEBIT_METHOD, PAY_BY_BANK_METHOD } from '../index';
import { initializeStripe } from '@fpc/api/stripe/Stripe';
import { PayByBank } from './PayByBank';
import { handleRejectError } from '@fpc/utils/errorHandling';
import { PaymentIntentConfirmRequest } from '@fpc/api/paymentapp/PaymentIntentCreationRequest';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import { isMultiMerchantFlow } from '@fpc/checkout/features/multi-merchant';
import { MultiMerchantStripeTransactionDetails } from '@fpc/checkout/features/multi-merchant/types/PaymentInfoToken';

interface AuthCardSelectProps {
  cards: PaymentMethodInfo[];
  stripe: Stripe;
  isReady: (value: boolean) => void;
  children?: ReactElement | ReactElement[];
  fordCustomerId: string;
}

export function AuthCardSelect(props: AuthCardSelectProps) {
  const {
    transaction,
    tokens,
    stripeCustomerId,
    redirectUrl,
    errorDispatch,
    isPreAuth,
    bffBaseUrl,
    redirectStatus
  } = useContext(StripeCheckoutContext);
  const [isSubmitProcessing, setSubmitProcessing] = useState(false);
  const [isUseNewCard, setIsUseNewCard] = useState(false);
  const dropDownItems: DropDownItem<PaymentMethodInfo>[] = [
    ...paymentMethodSelect(props.cards),
    {
      label: i18next.t<string>(
        translationKeys.checkout.useNewPaymentDropdownText
      ),
      ariaLabel: i18next.t<string>(
        translationKeys.checkout.useNewPaymentDropdownText
      ),
      value: NEW_PAYMENT_METHOD_INFO
    }
  ];
  const [isPayByBank, setPayByBank] = useState(false);
  const [paymentClientSecret, setPaymentClientSecret] = useState('');
  const [stripeConnect, setStripeConnect] = useState<Stripe | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodInfo>(
    dropDownItems[0].value
  );
  const [message, setMessage] = useState('');

  useEffect(() => {
    props.isReady(true);
  }, []);

  useEffect(() => {
    if (redirectStatus == 'failed') {
      setMessage(
        i18next.t<string>(translationKeys.checkout.redirectStatusFailure)
      );
    }
  }, []);

  useEffect(() => {
    if (isPayByBank) {
      setStripeConnect(null);
      const authPaymentRequest: AuthPaymentRequest = {
        checkoutTokens: tokens,
        stripeCustomerId: stripeCustomerId!,
        paymentMethodId: null,
        paymentMethodType: PAY_BY_BANK_METHOD,
        isSavePaymentMethod: false,
        setPaymentMethodAsDefault: false,
        fordCustomerId: props.fordCustomerId,
        paymentInitiator: PAYMENT_INITIATOR.CUSTOMER,
        isPreAuth: false,
        bffBaseUrl: bffBaseUrl!
      };
      Promise.all([
        initializeStripe(
          (transaction as StripeTransactionDetails).merchantAccountId
        ),
        makeAuthenticatedPayment(authPaymentRequest)
      ]).then((results) => {
        setStripeConnect(results[0]);
        let paymentResponse = results[1];
        setPaymentClientSecret(paymentResponse.paymentIntentClientSecret);
      });
    }
  }, [isPayByBank]);

  useEffect(() => {
    if (paymentMethod.paymentMethodId === 'useNewCard') {
      setIsUseNewCard(true);
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (isUseNewCard) {
      props.isReady(false);
    } else if (
      !isUseNewCard &&
      paymentMethod.paymentMethodId === 'useNewCard'
    ) {
      setPaymentMethod(dropDownItems[0].value);
    }
  }, [isUseNewCard, paymentMethod]);

  function authRedirect(response: PaymentResponse) {
    if (isMultiMerchantFlow(transaction)) {
      window.location.href = buildManualRedirectUrlForMultiMerchant(
        redirectUrl,
        response as MultiMerchantPaymentResponse
      );
    } else {
      const singleMerchantTransaction = transaction as StripeTransactionDetails;
      window.location.href = buildManualRedirectUrl(
        redirectUrl,
        singleMerchantTransaction.merchantAccountId,
        response.paymentIntentClientSecret,
        paymentMethod.type
      );
    }
  }

  function handlePayButton(event: SyntheticEvent) {
    if (event) {
      event.preventDefault();
    }

    setSubmitProcessing(true);
    const bffUrl = bffBaseUrl ?? '';
    if (paymentMethod.type === ACSS_DEBIT_METHOD) {
      const authPaymentRequestForAccessDebit: AuthPaymentRequest = {
        checkoutTokens: tokens,
        stripeCustomerId: stripeCustomerId!,
        paymentMethodId: paymentMethod.paymentMethodId,
        paymentMethodType: paymentMethod.type,
        isSavePaymentMethod: false,
        setPaymentMethodAsDefault: false,
        fordCustomerId: props.fordCustomerId,
        paymentInitiator: PAYMENT_INITIATOR.CUSTOMER,
        isPreAuth: false,
        bffBaseUrl: bffBaseUrl!
      };
      makeAuthenticatedPayment(authPaymentRequestForAccessDebit)
        .then(async (response) => {
          const paymentIntentConfirm: PaymentIntentConfirmRequest = {
            paymentIntentId: response.paymentIntentId,
            merchantAccountId: (transaction as StripeTransactionDetails)
              .merchantAccountId
          };
          confirmPaymentIntentConnectAccount(
            paymentIntentConfirm,
            tokens.bearerToken,
            bffUrl
          )
            .then(async () => {
              window.location.href = buildManualRedirectUrl(
                redirectUrl,
                (transaction as StripeTransactionDetails).merchantAccountId,
                response.paymentIntentClientSecret,
                paymentMethod.type
              );
            })
            .catch(async (reject) => {
              let errorMessage = handleRejectError(
                reject,
                'Failed confirming payment',
                errorDispatch,
                CHECKOUT_ELEMENT
              );
              setMessage(errorMessage);
              setSubmitProcessing(false);
            });
        })
        .catch(async (reject) => {
          let errorMessage = handleRejectError(
            reject,
            'Failed payment',
            errorDispatch,
            CHECKOUT_ELEMENT
          );
          setMessage(errorMessage);
          setSubmitProcessing(false);
        });
    } else {
      /*
       * For Single Merchant Flow:
       * - isSavePaymentMethodForDetach is always false for existing cards.
       *
       * For Multi-Merchant Flow:
       * - isSavePaymentMethodForDetach is true since we handle the attach and detach of payment methods in the payment app based on the flag.
       */
      const isSavePaymentMethodForDetach = !!isMultiMerchantFlow(transaction);
      const authPaymentRequest: AuthPaymentRequest = {
        checkoutTokens: tokens,
        stripeCustomerId: stripeCustomerId!,
        paymentMethodId: paymentMethod.paymentMethodId,
        paymentMethodType: paymentMethod.type,
        isSavePaymentMethod: isSavePaymentMethodForDetach,
        setPaymentMethodAsDefault: false,
        fordCustomerId: props.fordCustomerId,
        paymentInitiator: PAYMENT_INITIATOR.CUSTOMER,
        isPreAuth,
        bffBaseUrl: bffUrl
      };
      makeAuthenticatedPayment(authPaymentRequest)
        .then(async (response) => {
          if (response.status === REQUIRES_ACTION_STATUS) {
            await callHandleNextAction(
              response,
              () => authRedirect(response),
              (error) =>
                setMessage(
                  error.message ??
                    i18next.t(translationKeys.common.technicalErrorPayment)
                )
            );
          } else {
            authRedirect(response);
          }
        })
        .catch(async (reject) => {
          setMessage(
            handleRejectError(
              reject,
              translationKeys.common.technicalErrorPayment,
              errorDispatch,
              CHECKOUT_ELEMENT
            )
          );
          setSubmitProcessing(false);
        });
    }
  }

  if (isUseNewCard) {
    return (
      <AuthNewPaymentMethod
        stripe={props.stripe}
        isReady={props.isReady}
        setIsOpen={setIsUseNewCard}
        fordCustomerId={props.fordCustomerId}
        isFirstCard={false}
      />
    );
  } else if (isPayByBank && paymentClientSecret != null && stripeConnect) {
    return (
      <PayByBank
        paymentIntentClientSecret={paymentClientSecret}
        stripeConnect={stripeConnect}
        setIsOpen={setPayByBank}
        isReady={props.isReady}
        fordCustomerId={props.fordCustomerId}
      />
    );
  } else {
    return (
      <div
        data-testid="card-select-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingBlockStart: '1em',
          gap: '1em'
        }}
      >
        {transaction.paymentMethodTypes.includes(
          PAY_BY_BANK_METHOD.toUpperCase()
        ) ? (
          <span
            aria-label={
              i18next.t<string>(
                translationKeys.checkout.useNewPaymentTextBeforePayByBank
              ) +
              i18next.t<string>(translationKeys.checkout.usePayByBank) +
              i18next.t<string>(translationKeys.checkout.useNewCardTextAfter)
            }
          >
            {i18next.t<string>(
              translationKeys.checkout.useNewPaymentTextBeforePayByBank
            )}
            <a
              href="#"
              role="link"
              style={{ cursor: 'pointer' }}
              onClick={(event) => {
                event.preventDefault();
                setIsUseNewCard(true);
              }}
            >
              {i18next.t<string>(
                translationKeys.checkout.useNewPaymentLinkText
              )}
            </a>
            {i18next.t<string>(translationKeys.checkout.usePayByBank)}
            <a
              href="#"
              role="link"
              style={{ cursor: 'pointer' }}
              onClick={(event) => {
                event.preventDefault();
                setPayByBank(true);
                props.isReady(false);
              }}
            >
              {i18next.t<string>(translationKeys.checkout.payByBankLinkText)}
            </a>
            {i18next.t<string>(translationKeys.checkout.useNewCardTextAfter)}
          </span>
        ) : (
          <span
            aria-label={
              i18next.t<string>(
                translationKeys.checkout.useNewPaymentTextBefore
              ) +
              i18next.t<string>(
                translationKeys.checkout.useNewPaymentLinkText
              ) +
              i18next.t<string>(translationKeys.checkout.useNewCardTextAfter)
            }
          >
            {i18next.t<string>(
              translationKeys.checkout.useNewPaymentTextBefore
            )}
            <a
              href="#"
              role="link"
              style={{ cursor: 'pointer' }}
              onClick={(event) => {
                event.preventDefault();
                setIsUseNewCard(true);
              }}
            >
              {i18next.t<string>(
                translationKeys.checkout.useNewPaymentLinkText
              )}
            </a>
            {i18next.t<string>(translationKeys.checkout.useNewCardTextAfter)}
          </span>
        )}

        <DropDown
          cssClass="payment-methods-menu"
          ariaLabel={i18next.t<string>(translationKeys.checkout.cardSelect)}
          items={dropDownItems}
          valueCallback={setPaymentMethod}
        />
        <TermsAndConditions translationGroup={translationKeys.checkout} />
        <div role={'alert'} style={errorTextStyle}>
          {message}
        </div>
        <PayButton
          isFormComplete={true}
          isSubmitProcessing={isSubmitProcessing}
          paymentButtonText={buttonPayNow(
            transaction.amount,
            transaction.currency
          )}
          onClick={handlePayButton}
        ></PayButton>
      </div>
    );
  }
}
