export const ACCOUNT_ID_PARAM = 'account_id';
export const PAYMENT_INTENT_PARAM = 'payment_intent';
export const PAYMENT_INTENT_SECRET_PARAM = 'payment_intent_secret';
export const PAYMENT_INTENT_CLIENT_SECRET_PARAM =
  'payment_intent_client_secret';
export const REDIRECT_STATUS_PARAM = 'redirect_status';
export const PAYMENT_TYPE_PARAM = 'payment_type';
export const CARD_PAYMENT_METHOD = 'card';
export const US_BANK_PAYMENT_METHOD = 'us_bank_account';
export const PAY_BY_BANK_METHOD = 'pay_by_bank';
export const PROMPT_PAY_METHOD = 'promptpay';
export const ACSS_DEBIT_METHOD = 'acss_debit';
export const ACSS_DEBIT = 'ACSS_DEBIT';
export const BACS_DIRECT_DEBIT = 'bacs_debit';
export const SEPA_PAY_METHOD = 'sepa_debit';

export const IDEAL_PAY_METHOD = 'ideal';
export const KLARNA = 'klarna';

export const CONNECT_PAYMENT_METHOD_TYPES = [
  BACS_DIRECT_DEBIT,
  PAY_BY_BANK_METHOD,
  ACSS_DEBIT_METHOD,
  IDEAL_PAY_METHOD
];

export const SAVE_PAYMENT_OPTIONS_NOT_NEEDED = [SEPA_PAY_METHOD];

export const PREAUTH_PAYMENT_METHODS = [CARD_PAYMENT_METHOD, SEPA_PAY_METHOD];

export const PLATFORM_PAYMENT_METHODS = 'platform-payment-methods';

export const CONNECT_PAYMENT_METHODS = 'connect-payment-methods';
export const PAYMENT_RESPONSE_PARAM = 'payment_response';
