import {
  Layout,
  LayoutObject,
  Stripe,
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripeElementsOptionsMode,
  StripeError,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { Dispatch, useEffect, useRef, useState } from 'react';
import useStateRef from '@fpc/reactutils/useStateRef';
import { useStripeLoadTimeout } from '@fpc/reactutils/useStripeLoadTimeout';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import i18next from '@fpc/common/i18n';

function useStripePaymentElements(
  stripe: Stripe,
  options: StripeElementsOptionsClientSecret | StripeElementsOptionsMode,
  elements: StripeElements,
  errorDispatch: Dispatch<ErrorCondition>,
  paymentMethodDisplayOrderOption: string | null,
  layout: Layout | LayoutObject = {
    type: 'accordion',
    defaultCollapsed: false,
    radios: true,
    spacedAccordionItems: false
  }
) {
  const [isPaymentMounted, setIsPaymentMounted] = useState(false);
  const [isFormComplete, setIsFormComplete, isFormCompleteRef] =
    useStateRef(false);

  const clientSecret = useRef(options.clientSecret);
  const [isFormRendered, setIsFormRendered] = useState<boolean>();

  useEffect(() => {
    if (options.clientSecret !== clientSecret.current) {
      elements.update(options);
      elements.fetchUpdates();
    }
  }, [options.clientSecret]);

  const errorTimeoutRef = useStripeLoadTimeout(errorDispatch);

  function mountPaymentElements(
    mountPoint: string,
    onChangeCallback: (event: StripePaymentElementChangeEvent) => void
  ) {
    if (!elements.getElement('payment')) {
      const paymentElement = elements?.create('payment', {
        wallets: { applePay: 'never', googlePay: 'never' },
        terms: { card: 'never' },
        layout,
        defaultValues: {
          billingDetails: {
            address: {
              country: i18next.language.split('-')[1]
            }
          }
        },
        paymentMethodOrder: paymentMethodDisplayOrderOption?.split(',')
      } as unknown as StripePaymentElementOptions);
      paymentElement?.mount(mountPoint);
      paymentElement?.on('change', (event: StripePaymentElementChangeEvent) => {
        errorTimeoutRef.current && clearTimeout(errorTimeoutRef.current);

        if (!isPaymentMounted) {
          setIsPaymentMounted(true);
        }

        if (event.complete) {
          setIsFormComplete(true);
        } else if (isFormCompleteRef) {
          setIsFormComplete(false);
        }
        onChangeCallback(event);
      });

      paymentElement?.on('ready', (event) => {
        setIsFormRendered(true);
      });

      paymentElement?.on(
        'loaderror',
        (event: { elementType: 'payment'; error: StripeError }) => {
          setIsFormRendered(false);

          if (event.error) {
            console.log('Detailed error message:', event.error.message);
          } else {
            console.warn('A Stripe error occurred.');
          }
        }
      );
    } else {
      elements.getElement('payment')?.mount(mountPoint);
    }
  }

  return {
    elements,
    mountPaymentElements,
    isPaymentMounted,
    isFormComplete,
    isFormRendered
  };
}

export default useStripePaymentElements;
