import i18next from '@fpc/common/i18n';
import { translationKeys } from '../i18n/en-US';
import { Button } from './Button';
import { FordPayUIStyle } from '../Styles';
import { FordStyles } from '../FordStyles';
import { useEffect } from 'react';
import { dispatchCheckoutFormOnloadEvent } from '@fpc/utils/dispatchEvent';
import { CHECKOUT_ELEMENT } from '@fpc/reactutils/checkoutContextProvider';

interface TechnicalErrorProps {
  resetFromError: () => void;
  unrecoverable?: boolean;
}

export function TechnicalError(props: TechnicalErrorProps) {
  useEffect(() => {
    dispatchCheckoutFormOnloadEvent(CHECKOUT_ELEMENT, {
      loaded: false,
      errorMessage: 'loaded with error'
    });
  }, []);

  return (
    <FordPayUIStyle role={'alert'} data-testid="technical-error">
      <h2 style={{ fontSize: '2.5em' }}>
        {i18next.t<string>(translationKeys.common.timeoutSorry)}
      </h2>
      <p
        style={{
          textAlign: 'left',
          fontSize: '1em',
          letterSpacing: '0',
          margin: '0 0 1em 0',
          color: FordStyles.color.gray3
        }}
      >
        {i18next.t<string>(translationKeys.common.timeoutError)}
      </p>
      {!props.unrecoverable && (
        <Button onClick={props.resetFromError}>
          {i18next.t<string>(translationKeys.common.timeoutTryAgain)}
        </Button>
      )}
    </FordPayUIStyle>
  );
}
