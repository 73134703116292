import React from 'react';
import {
  CheckoutInput,
  InputGridArea,
  InputLabel
} from '@fpc/common/components/InputComponents';
import { TextInput } from '@fpc/checkout/payu/Style';

interface CardExpiryYearInputProps {
  expiryYear: string;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CardExpiryYearInput: React.FC<CardExpiryYearInputProps> = ({
  expiryYear,
  handleKeyPress,
  handleChange,
  handleBlur
}) => {
  return (
    <InputGridArea
      style={{
        gridArea: '2 / 2 / auto / 2'
      }}
    >
      <InputLabel htmlFor="expiry-year">{'Expiry Year'}</InputLabel>
      <CheckoutInput
        className="form-control"
        id="expiry-year"
        style={{
          borderBottomLeftRadius: '.25rem',
          borderTopLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
          borderTopRightRadius: '.25rem',
          padding: '0.9em 0.5em',
          height: '1.5rem'
        }}
      >
        <TextInput
          id="year"
          data-encrypt="year"
          placeholder={'YYYY'}
          data-testid={'expiry-year'}
          minLength={0}
          maxLength={4}
          type={'text'}
          onKeyDown={handleKeyPress}
          style={{
            paddingLeft: '0rem'
          }}
          value={expiryYear}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </CheckoutInput>

      <span
        className="helper-text"
        id="exp-year-help"
        data-testid="exp-year-help"
      />
    </InputGridArea>
  );
};

export default CardExpiryYearInput;
