import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down_16x16.svg';
import { ReactComponent as ChevronUCLRightIcon } from '../icons/ucl-right-arrow.svg';
import styled from 'styled-components/macro';

const ChevronUpIcon = styled(ChevronDownIcon)`
  transform: rotate(-180deg);
`;

const ChevronRightIcon = styled(ChevronUCLRightIcon)`
  margin-left: 8px;
`;

export const ChevronIcon = {
  Down: ChevronDownIcon,
  Up: ChevronUpIcon,
  Right: ChevronRightIcon
};
