import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ServiceHandler from './services/service-handler';
import Layout from './layout/layout';
import { ScrollToTop } from './components/common/scroll-to-top/scroll-to-top';
import { ContextProviders } from './context/context-providers';
import * as amplitude from '@amplitude/analytics-browser';
import TermsConditionsLink from './components/sections/terms-conditions-link-view/terms-conditions-link-view';
import { NavBar } from './components/sections/nav-bar/nav-bar';
import { isMobile } from './services/mobile-bridge/mobile-bridge';
import UserService from './services/user-service/user-service';
import { APIHelperContext } from './context/api-helper-context';
import { CommonRoutes } from './routes-common';
var App = function () {
    var cfg = ServiceHandler.ConfigService;
    var _a = useState(false), appLoaded = _a[0], setAppLoaded = _a[1];
    var authService = ServiceHandler.AuthenticationService;
    var apiHelper = useContext(APIHelperContext)[0];
    var searchParams = new URLSearchParams(window.location.search);
    useEffect(function () {
        var identify = new amplitude.Identify();
        identify
            .set('loyaltyLanguage', cfg.LANGUAGE_CODE)
            .set('loyaltyCountry', cfg.PROGRAM_COUNTRY)
            .set('loyaltyBrand', cfg.BRAND_ID)
            .set('loyaltyMobile', isMobile())
            .set('utmMedium', searchParams.get('utm_medium'))
            .set('utmSource', searchParams.get('utm_source'))
            .set('utmCampaign', searchParams.get('utm_campaign'));
        amplitude.identify(identify);
        ServiceHandler.AuthenticationService.onIsAuthenticated().then(function () {
            var _a, _b, _c, _d;
            setAppLoaded(true);
            if (isMobile()) {
                var userService = new UserService();
                userService.request(apiHelper).then(function (response) {
                    var _a;
                    if ((_a = response === null || response === void 0 ? void 0 : response.profile) === null || _a === void 0 ? void 0 : _a.userGuid)
                        amplitude.setUserId(response.profile.userGuid.toLowerCase());
                });
            }
            else {
                if ((_b = (_a = window['fma']) === null || _a === void 0 ? void 0 : _a.CATBundle) === null || _b === void 0 ? void 0 : _b.userId)
                    amplitude.setUserId((_d = (_c = window['fma']) === null || _c === void 0 ? void 0 : _c.CATBundle) === null || _d === void 0 ? void 0 : _d.userId.toLowerCase());
            }
        });
    }, [authService]);
    useEffect(function () {
        var _a, _b;
        if ((_b = (_a = ServiceHandler.AuthenticationService) === null || _a === void 0 ? void 0 : _a.getCatBundle()) === null || _b === void 0 ? void 0 : _b.userId)
            amplitude.setUserId(ServiceHandler.AuthenticationService.getCatBundle().userId.toLowerCase());
    }, [ServiceHandler.AuthenticationService.getCatBundle()]);
    return (React.createElement(React.Fragment, null, appLoaded === true && (React.createElement(React.Suspense, { fallback: React.createElement("div", null) },
        React.createElement(Router, { basename: cfg.APP_BASEPATH },
            React.createElement(ContextProviders, null,
                React.createElement(ScrollToTop, null),
                React.createElement(Layout, null,
                    React.createElement(NavBar, null),
                    React.createElement(CommonRoutes, null)),
                React.createElement(TermsConditionsLink, null)))))));
};
export default App;
